import React, { useEffect } from "react";
import "./style.css";
import banner from "../../images/home/banner.jpg";
import bannerDown from "../../images/home/Tsubaki-Lemon-Drop-Full-1.jpg";
import image1 from "../../images/home/AWAKE.jpg";
import image2 from "../../images/home/Bob-S-HT-cut-pile-with-low-loop-white-and-carved-ribbons.jpg";
import image3 from "../../images/home/DOUBLE-LOW-LOOP.jpg";
import image4 from "../../images/home/IMG_5793.jpg";
import Carousel from "../../components/Carousel";
import { Button } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { Link } from "react-router-dom";
import image5 from "../../images/home/550lb-wool-with-sculpting-HT.jpg";
import image6 from "../../images/home/2020-RDS-nature-A-Visualization.jpg";
import image7 from "../../images/home/Abagayle-Up-Close.jpg";
import image8 from "../../images/home/Boorah-Bubble-Up-Close.jpg";
import image9 from "../../images/home/IMG_0916.jpg";
import image10 from "../../images/home/SOAR-Visualization.jpg";
import image11 from "../../images/home/RANDOM-Visualization.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Home = () => {
  document.title = "The Rug Designer's Studio";
  useEffect(() => {
    // Create a link element for the canonical tag
    const canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://therugdesignersstudio.com/"; // Update with the correct URL

    // Append the canonical link tag to the head of the document
    document.head.appendChild(canonicalLink);

    // Cleanup the link tag on component unmount
    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);
  function handlePostLog() {
    var formData = new FormData();
    formData.append("product", "galaincha");
    formData.append("event", `TrainingLinkDeborah,home`);

    // Create the HTTP request
    const request = new XMLHttpRequest();
    request.open(
      "POST",
      "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx"
    );

    // Send the form data
    request.send(formData);
  }
  return (
    <div>
      <div id="topImage">
        <LazyLoadImage
          src={banner}
          height="600px"
          width="100%"
          alt="The rug designer's studio banner"
        />
        <div id="textAboveImage">
          <h1 id="homeHeading">The Rug Designer's Studio</h1>
          <hr id="solidLine" width="75px" />
          <div id="homeHeading1">
            <ul>
              <li>
                <Link to="/gallery" title="Gallery">
                  Design,&nbsp;&nbsp;
                </Link>
              </li>
              <li>
                <Link to="/services" title="Services provided">
                  Consultation,&nbsp;&nbsp;
                </Link>
              </li>
              <li>
                <Link
                  to="https://galaincha.com.np/training"
                  target="_blank"
                  title="Learn more about galaincha training."
                  onClick={handlePostLog}
                >
                  Training
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="homeQuote">
        <span>
          "A designer's true foundation and creative integrity are reflected in
          the work they create through collaborative efforts with others.
          Patience, skill, kindness, and compassion lead these creative efforts
          and reveal amazing results."{" "}
        </span>
        <p id="homeQuoteTitle">-Deborah Hernandez</p>
      </div>

      <div className="bespokecontainer">
        <h2 className="heading2">BESPOKE RUGS</h2>
        <hr id="solidLine" width="75px" />
        <div>
          <Carousel
            imageArray={[
              image5,
              image6,
              image7,
              image8,
              image9,
              image10,
              image11,
            ]}
          />
        </div>
        <p id="beSpokeTitle">
          Unique custom artisanal rugs - created for your projects as requested
        </p>
        <Link to="/projects" title="Learn more about projects.">
          <Button
            variant="contained"
            endIcon={<NavigateNext />}
            color="primary"
            className="learnmoreButton"
            title="Learn more about projects."
            aria-label="Explore our projects"
          >
            Explore Projects
          </Button>
        </Link>
      </div>

      <div id="topImage">
        <LazyLoadImage
          src={bannerDown}
          height="600px"
          width="100%"
          alt="Tsubaki Lemon Drop Full"
        />
        <div id="textAboveImage2">
          <div id="leftHalfJourney"></div>
          <div id="rightHalfJourney">
            <h2 id="journey">My Journey</h2>
            <hr id="solidLine" width="75px" style={{ marginLeft: "0%" }} />
            <p id="journeypara">
              My love of fibers and fiber manipulation paved the path for
              creating unique artwork for bespoke rugs. Through collaborations
              with talented, trusted &amp; highly skilled artisans{" "}
              <strong>The Rug Designer's Studio</strong> produces beautiful art
              for your floor.
            </p>
            <Link
              to="/about"
              title="Learn more about carpet designer, educator and certified color specialist - Deborah Hernandez"
            >
              <Button
                variant="contained"
                endIcon={<NavigateNext />}
                color="primary"
                className="learnmoreButton1"
                title="Learn more about carpet designer, educator and certified color specialist - Deborah Hernandez"
                aria-label="Learn about Deborah Hernandez, carpet designer, educator, and certified color specialist"
              >
                Learn About Deborah Hernandez
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="containerService">
        <div className="left-halfcontainerService">
          <h2 className="heading2">SERVICES</h2>
          <hr id="solidLine" width="75px" style={{ marginLeft: "0%" }} />
          <p id="servicesText">
            By collaborating with trusted highly skilled artisans from different
            parts of the world I bring unique designs to life for my clients. I
            use only the highest quality fibers and work with artisans who
            master these skills and communicate clearly producing exemplary
            results.
            <br />
            <br />
            I offer training in custom rug design from traditional and digital
            drafting to surface specification and work as a liaison to makers
            for product accuracy.
            <br />
            <br />
            Consultations range from individual rugs to wide-spanning projects
            with multiple rugs.
          </p>

          <Link to="/services" title="Learn more about the services provided.">
            <Button
              variant="contained"
              endIcon={<NavigateNext />}
              color="primary"
              className="learnmoreButton1"
              title="Learn more about the services provided."
              aria-label="Explore the services offered."
            >
              Explore Services
            </Button>
          </Link>
        </div>
        <div className="right-halfcontainerService">
          <LazyLoadImage src={image1} className="serviceImage1" alt="Awake" />
          <LazyLoadImage
            src={image2}
            className="serviceImage2"
            alt="Bob-S-HT-cut pile with low loop white and carved ribbons"
          />
          <LazyLoadImage
            src={image3}
            className="serviceImage1"
            alt="Double low loop"
          />
          <LazyLoadImage
            src={image4}
            className="serviceImage2"
            alt="Deborah Hernandez"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;

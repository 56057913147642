import React, { useEffect } from "react";
import banner from "../../images/home/banner.jpg";
import debImage from "../../images/about/Deb-at-the-Cloisters.jpg";
import "./style.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const About = () => {
  document.title = "About Deborah Hernandez | The Rug Designer's Studio";
  useEffect(() => {
    // Create a link element for the canonical tag
    const canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://therugdesignersstudio.com/about"; // Update with the correct URL

    // Append the canonical link tag to the head of the document
    document.head.appendChild(canonicalLink);

    // Cleanup the link tag on component unmount
    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);
  function handlePostLog() {
    var formData = new FormData();
    formData.append("product", "galaincha");
    formData.append("event", `TrainingLinkDeborah,about`);

    // Create the HTTP request
    const request = new XMLHttpRequest();
    request.open(
      "POST",
      "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx"
    );

    // Send the form data
    request.send(formData);
  }
  return (
    <>
      <div id="topImage">
        <LazyLoadImage
          src={banner}
          height="600px"
          width="100%"
          alt="The rug designer's studio banner"
        />
        <div id="textAboveImage">
          <h1 id="homeHeading">Deborah Hernandez</h1>
          <h2 id="homeHeading1">
            Educator, Carpet Designer, Certified Color Specialist, Mentor
          </h2>
        </div>
      </div>
      <div className="containerAbout">
        <div className="left-halfcontainerAbout">
          <p id="aboutText">
            My professional design journey was initiated with hand drawing and
            painting rugs as a studio designer in New York's D & D building. For
            years I enjoyed creating artwork for custom rugs and rug collections
            working with many well-known companies, interior designers, and
            architects for their unique rug projects. My career blossomed when
            an opportunity in commercial carpet design surfaced in 2001
            providing training on state-of-the-art carpet design software. From
            there my interest in rugs, fibers, and surface manipulation grew
            coupled with my knowledge of digital design on grid-based software.
            With an interest in sustainability, natural fibers, ply blending,
            and unique surface manipulation, I now create artwork and specify
            surface execution for unique bespoke custom rugs and rug collections
            via artisan connections in Nepal, India, and Thailand. I primarily
            work with artists, designers, architects, and private clients. I am
            an Adjunct Professor at The Fashion Institute of Technology in New
            York City sharing my knowledge through teaching rug design and color
            classes predominantly. I find great joy in hosting student interns
            as well as placing them into internships in creative design
            positions. Dive into the world of rug design with confidence, as I
            offer my expertise as a certified Galaincha software trainer. Access
            my training module here:{" "}
            <a
              href="https://galaincha.com.np/training/"
              target="_blank"
              onClick={handlePostLog}
            >
              https://galaincha.com.np/training/
            </a>{" "}
            to unlock your potential in utilizing this powerful software for
            stunning rug creations Additionally, I take pride as a well-trusted
            global placement specialist for the custom rug and carpet industry.
          </p>
        </div>
        <div className="right-halfcontainerAbout">
          <LazyLoadImage
            src={debImage}
            alt="Deborah Hernandez"
            id="debImageAbout"
            effect="blur"
          />
        </div>
      </div>
    </>
  );
};

export default About;

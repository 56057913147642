import React, { useEffect } from "react";
import banner from "../../images/home/banner.jpg";
import "./style.css";
import image1 from "../../images/gallery/AD_3781_ground-only_rug.jpg";
import image2 from "../../images/gallery/AD_3782_ground-only_rug.jpg";
import image3 from "../../images/gallery/AD_3785_ground-only_rug.jpg";
import image4 from "../../images/gallery/AD_3786_ground-only_rug.jpg";
import image5 from "../../images/gallery/AWAKE-beautiful-colors.jpg";
import image6 from "../../images/gallery/AWAKE-weavers-platform-handknotted.jpg";
import image7 from "../../images/gallery/Awake-in-progress.jpg";
import image8 from "../../images/gallery/HT-wool-loop-ground-w-tip-sheer-with-embossed-cut-pile-daisy-and-carving.jpg";
import image9 from "../../images/gallery/IMG_0606.jpg";
import image10 from "../../images/gallery/IMG_3092.jpg";
import image11 from "../../images/gallery/IMG_5597.jpg";
import image12 from "../../images/gallery/PG-D1-AWAKE-weavers-platform-handknotted.jpg";
import image13 from "../../images/gallery/WnWsamplesetupCOLOR-NC-001-2.jpg";
import image14 from "../../images/gallery/images-yarn-weaving-rugs.jpg";
import image15 from "../../images/gallery/new-tsubaki-collection-of-handmade-silk-and-wool-rugs-by-warp-weft-default.jpg";
import image16 from "../../images/gallery/rug3.jpg";

import pdfImage1 from "../../images/gallery/pdf1.jpg";
import pdfImage2 from "../../images/gallery/pdf2.jpg";
import pdfImage3 from "../../images/gallery/pdf4.jpg";
import pdfImage4 from "../../images/gallery/2011-2012-Warp-Weft-1-min.jpg";
import pdfImage5 from "../../images/gallery/edc0916-inspiration-schools-hi-1-2-min.jpg";
import pdfImage6 from "../../images/gallery/Warp-Weft-sponsors-student-design-contest-1-min.jpg";
import pdfImage7 from "../../images/gallery/Denize-Sofia-Maaloe-the-First-Warp-We.-1-min.jpg";
import pdfImage8 from "../../images/gallery/2014_04_Elle_Decor-tsubaki-1-min.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  Card,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  styled,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
const Gallery = () => {
  document.title = "Gallery | The Rug Designer's Studio";
  useEffect(() => {
    // Create a link element for the canonical tag
    const canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://therugdesignersstudio.com/gallery"; // Update with the correct URL

    // Append the canonical link tag to the head of the document
    document.head.appendChild(canonicalLink);

    // Cleanup the link tag on component unmount
    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);
  const itemData = [
    {
      img: image1,
      title: "AD_3781_ground-only_rug",
    },
    {
      img: image2,
      title: "AD_3782_ground-only_rug",
    },
    {
      img: image3,
      title: "AD_3785_ground-only_rug",
    },
    {
      img: image4,
      title: "AD_3786_ground-only_rug",
    },
    {
      img: image5,
      title: "AWAKE-beautiful-colors",
    },
    {
      img: image6,
      title: "AWAKE-weavers-platform-handknotted",
    },
    {
      img: image7,
      title: "Awake-in-progress",
    },
    {
      img: image8,
      title:
        "HT-wool-loop-ground-w-tip-sheer-with-embossed-cut-pile-daisy-and-carving",
    },
    {
      img: image9,
      title: "IMG_0606",
    },
    {
      img: image10,
      title: "IMG_3092",
    },
    {
      img: image11,
      title: "IMG_5597",
    },
    {
      img: image12,
      title: "PG-D1-AWAKE-weavers-platform-handknotted",
    },
    {
      img: image13,
      title: "WnWsamplesetupCOLOR-NC-001-2",
    },
    {
      img: image14,
      title: "images-yarn-weaving-rugs",
    },
    {
      img: image15,
      title:
        "new-tsubaki-collection-of-handmade-silk-and-wool-rugs-by-warp-weft-default",
    },
    {
      img: image16,
      title: "rug3",
    },
  ];

  const pdfData = [
    {
      img: pdfImage1,
      title: "RNAD January 2022",
      link: "https://therugdesignersstudio.com/pdfs/132857912093905732_RNADJanuary2022-min.pdf",
    },
    {
      img: pdfImage2,
      title: "RNAD March 2022",
      link: "https://therugdesignersstudio.com/pdfs/132924367390612846_March2022RugNewsandDesignMagazine-1-min.pdf",
    },
    {
      img: pdfImage3,
      title: "RNAD June 2022",
      link: "https://therugdesignersstudio.com/pdfs/2011-2012-Warp-Weft-min.pdf",
    },
    {
      img: pdfImage4,
      title: "2011 2012 Warp & Weft",
      link: "https://therugdesignersstudio.com/pdfs/2011-2012-Warp-Weft-min.pdf",
    },
    {
      img: pdfImage5,
      title: "edc0916 inspiration-schools-hi",
      link: "https://therugdesignersstudio.com/pdfs/edc0916-inspiration-schools-hi-min.pdf",
    },
    {
      img: pdfImage6,
      title: "Warp & Weft sponsors student design contest",
      link: "https://therugdesignersstudio.com/pdfs/Warp-Weft-sponsors-student-design-contest-min.pdf",
    },
    {
      img: pdfImage7,
      title: "Denize Sofia Maaloe the First Warp & Weft",
      link: "https://therugdesignersstudio.com/pdfs/Denize-Sofia-Maaloe-the-First-Warp-We.-min.pdf",
    },
    {
      img: pdfImage8,
      title: "2014_04_Elle_Decor tsubaki",
      link: "https://therugdesignersstudio.com/pdfs/2014_04_Elle_Decor-tsubaki-min.pdf",
    },
  ];
  const matches = useMediaQuery("(min-width:1200px)");
  const matchesMob = useMediaQuery("(min-width:600px)");

  return (
    <>
      <div id="topImage">
        <LazyLoadImage
          src={banner}
          height="600px"
          width="100%"
          alt="The Rug Designer's Studio Banner"
        />
        <div id="textAboveImage">
          <h1 id="homeHeading">Gallery</h1>
        </div>
      </div>
      <div className="galleryContainer">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {itemData.map((image, key) => (
            <Grid item xs={12} sm={6} lg={3} key={key}>
              <div style={{ margin: "0.2rem" }}>
                <LazyLoadImage src={image.img} width="100%" alt={image.title} />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <br />
      <br />
      <div className="galleryContainer">
        <h1 className="heading2">ARTICLES</h1>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {pdfData.map((image, key) => (
            <Grid item xs={12} sm={6} lg={3} key={key}>
              <div style={{ marginBottom: "1rem", marginRight: "1rem" }}>
                <a href={image.link} target="_blank" aria-label={image.title}>
                  <LazyLoadImage
                    src={image.img}
                    width="100%"
                    alt={image.title}
                    style={{ cursor: "pointer" }}
                  />
                </a>
                <p className="galleryArticleText">{image.title}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default Gallery;

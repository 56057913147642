import React from "react";
import Slider from "react-multi-carousel";
import "./style.css";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  bigdesktop: {
    breakpoint: { max: 3000, min: 1800 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Carousel = ({ imageArray }) => {
  return (
    <Slider responsive={responsive} infinite={true} autoPlay={true}>
      {imageArray.map((image, key) => (
        <div key={key}>
          <img
            src={image}
            className="carouselImageHome"
            alt={image.split("/static/media/")[1].split(".")[0]}
          />
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;

import React, { useState, useEffect } from "react";
import banner from "../../images/home/banner.jpg";
import "./style.css";
import { Button, TextField } from "@mui/material";

import {
  Send,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
} from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Contact = () => {
  document.title = "Contact | The Rug Designer's Studio";
  useEffect(() => {
    // Create a link element for the canonical tag
    const canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://therugdesignersstudio.com/contact"; // Update with the correct URL

    // Append the canonical link tag to the head of the document
    document.head.appendChild(canonicalLink);

    // Cleanup the link tag on component unmount
    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, [5000]);
    var formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("platform", "deborah");

    // Create the HTTP request
    const request = new XMLHttpRequest();
    request.open(
      "POST",
      "https://explorug.com/archanastools/Utilities/Email.aspx"
    );

    // Send the form data
    request.send(formData);
    setName("");
    setEmail("");
    setMessage("");
  };
  return (
    <>
      <div id="topImage">
        <LazyLoadImage
          src={banner}
          height="600px"
          width="100%"
          alt="The Rug Designer's Studio"
        />
        <div id="textAboveImage">
          <h1 id="homeHeading">Contact Me</h1>
        </div>
      </div>

      <div id="contactContainer">
        <div id="leftContactContainer">
          <h2 id="headingContact">LETS GET CONNECTED</h2>
          <form action="" id="contactForm" onSubmit={(e) => handleSubmit(e)}>
            <TextField
              id="outlined-basic"
              label="Your Name"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Email Address"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Message"
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              variant="contained"
              endIcon={<Send />}
              color="primary"
              className="learnmoreButton"
              type="submit"
              style={{ width: "150px" }}
            >
              SEND MESSAGE
            </Button>
          </form>
        </div>
        <div id="rightContactContainer">
          <h2 id="headingContact">CONTACT INFO</h2>
          <h3 className="headingContact3">Address</h3>
          <p className="paraContact3">Staten Island, New York, 10305, USA</p>
          <h3 className="headingContact3">Email Us</h3>
          <p className="paraContact3">dlhernandezinc@gmail.com</p>
          <h3 className="headingContact3">Call Us</h3>
          <p className="paraContact3">718-288-6165 /+1 718-288-6165</p>
          <h3 className="headingContact3">Follow Us</h3>
          <div id="socialIcons">
            <a
              href="https://www.Facebook.com/DLHernandezINC/"
              target="_blank"
              aria-label="Facebook"
            >
              <Facebook color="primary" fontSize="large" />
            </a>
            <a
              href="https://twitter.com/dlhernandezinc?t=aew4MmNFvcupgYdcuHIGkA&s=07&fbclid=IwAR2ymbN5pltyMe5KYVyvDXJsc1mNJzFo0r6fXDDZtookbsi2Wi0Qc3l-_jM"
              target="_blank"
              aria-label="Twitter"
            >
              <Twitter color="primary" fontSize="large" />
            </a>
            <a
              href="https://www.instagram.com/rugdesignersstudio/"
              target="_blank"
              aria-label="Instagram"
            >
              <Instagram color="primary" fontSize="large" />
            </a>
            <a
              href="https://www.linkedin.com/in/deborah-hernandez-ma-2a835a7/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <LinkedIn color="primary" fontSize="large" />
            </a>
          </div>
          <br />
        </div>
      </div>
      {alert && (
        <div className="alert" id="alert">
          Thank you for contacting us.
          <span className="close" id="close" onClick={() => setAlert(false)}>
            &times;
          </span>
        </div>
      )}
    </>
  );
};

export default Contact;
